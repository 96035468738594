<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{formFields.id ? `Edit ${operationTitle}` : `Add ${operationTitle}`}}
          </template>
          <template v-slot:toolbar>
            <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                      size="sm"
                      variant="primary"
                      :disabled="global.pendingRequests > 0"
            >
              <i v-show="global.pendingRequests > 0"
                 class="fa fa-spin fa-spinner"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
            <b-button variant="danger" size="sm" class="mt-3"
                      @click="handleOperationClose()">
              <i class="fa fa-arrow-left fa-sm"></i> Cancel
            </b-button>
          </template>
          <template v-slot:body>
            <v-card>
              <div class="guests-operation">
                <b-container fluid>
                  <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                    <b-row>
                      <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
                        <b-row>
                          <b-col sm="4">
                            <b-form-group
                                :invalid-feedback="formErrors.first('name')"
                                label="Full Name *"
                                label-for="name"
                            >
                              <b-form-input
                                  :state="((formErrors.has('name') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="name"
                                  ref="name"
                                  type="text"
                                  v-model="formFields.name"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4">
                            <b-form-group
                                :invalid-feedback="formErrors.first('email')"
                                label="Email *"
                                label-for="email">
                              <b-form-input
                                  :state="((formErrors.has('email') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="email"
                                  type="email"
                                  v-model="formFields.email"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4">
                            <b-form-group
                                :invalid-feedback="formErrors.first('phone')"
                                label="Phone *"
                                label-for="phone"
                            >
                              <b-form-input
                                  :state="((formErrors.has('phone') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="phone"
                                  type="text"
                                  v-model="formFields.phone"
                                  v-numericOnly.keyup
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row><!--/b-row-->
                        <b-row>
                          <b-col sm="4">
                            <b-form-group
                                :invalid-feedback="formErrors.first('guest_room_id')"
                                label="Guest Rooms"
                                label-for="guest_room_id"
                            >
                              <treeselect
                                  :options="dropdowns.guestRooms"
                                  :state="((formErrors.has('guest_room_id') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="guest_room_id"
                                  type="text"
                                  v-model="formFields.guest_room_id"
                              ></treeselect>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4">
                            <b-form-group
                                :invalid-feedback="formErrors.first('group_id')"
                                label="Group"
                                label-for="group_id"
                            >
                              <treeselect
                                  :class="[{'invalid is-invalid': (formErrors.has('group_id'))}]"
                                  :multiple="false"
                                  :options="dropdowns.groups"
                                  id="group"
                                  label="group_id"
                                  v-model="formFields.group_id"
                              />
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4">
                            <b-form-group
                                :invalid-feedback="formErrors.first('pincode')"
                                label="Pincode"
                                label-for="pincode"
                            >
                              <b-form-input
                                  :class="[{'invalid is-invalid': (formErrors.has('pincode'))}]"
                                  id="pincode"
                                  label="pincode"
                                  v-model="formFields.pincode"
                              />
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row>
                        <b-row>
                          <b-col sm="4">
                            <b-form-group
                                :invalid-feedback="formErrors.first('country_id')"
                                label="Country"
                                label-for="country_id"
                            >
                              <b-form-select
                                  :options="dropdowns.countries"
                                  :state="((formErrors.has('country_id') ? false : null))"
                                  v-model="formFields.country_id"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4">
                            <b-form-group
                                :invalid-feedback="formErrors.first('state_id')"
                                label="State"
                                label-for="state_id"
                            >
                              <b-form-select
                                  :options=" _.filter(dropdowns.states, (item) => item.country_id === formFields.country_id)"
                                  :state="((formErrors.has('state_id') ? false : null))"
                                  v-model="formFields.state_id"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4">
                            <b-form-group
                                :invalid-feedback="formErrors.first('city')"
                                label="City"
                                label-for="city"
                            >
                              <b-form-input
                                  :state="((formErrors.has('city') ? false : null))"
                                  id="city"
                                  type="text"
                                  v-model="formFields.city"
                              >
                              </b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row>
                        <b-row
                            v-if="$global.hasPermission('owncompanyprofilesview')">
                          <b-col sm="4" v-if="!formFields.id">
                            <b-form-group
                                :invalid-feedback="formErrors.first('event_id')"
                                label="Select Event"
                                label-for="event_id"
                            >
                              <b-form-select
                                  :options="dropdowns.events"
                                  :state="((formErrors.has('event_id') ? false : null))"
                                  id="event_id"
                                  v-model="formFields.event_id"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="12">
                            <b-form-group
                                :invalid-feedback="formErrors.first('postal_address')"
                                label="Postal Address"
                                label-for="postal_address"
                            >
                              <b-form-textarea
                                  :state="((formErrors.has('postal_address') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="postal_address"
                                  type="text"
                                  v-model="formFields.postal_address"
                              ></b-form-textarea>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row><!--/b-row-->
                        <hr />
                        <b-row class="operation-footer">
                          <b-col sm="12">
                            <b-button
                                :disabled="global.pendingRequests > 0"
                                size="sm"
                                type="submit"
                                variant="primary"
                            >
                              <i class="fa fa-spin fa-spinner"
                                 v-show="global.pendingRequests > 0"></i>
                              <i class="fa fa-save fa-sm"></i>
                              Save
                            </b-button>
                            <b-button @click="handleOperationClose()" class="ml-3"
                                      size="sm"
                                      variant="danger">
                              <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-col><!--/b-col-->
                    </b-row><!--/b-row-->
                  </form><!--/form-->
                </b-container><!--/b-container-->
              </div><!--/.guests-operation-->
            </v-card>
          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
  import KTCard from "@/view/content/Card.vue";
  import moduleMixin from "./module.mixin";
  import { mapState } from "vuex";
  import { getEventId } from "@/util/globalFunction";

  export default {
    mixins: [moduleMixin],
    components: {
      KTCard,
    },
    mounted() {
      if ( this.$route.params && this.$route.params.type && this.$route.params.id ) {
        this.handleEditOperation(this.$route.params.id);
      }
      this.getGuestRooms();
      if ( getEventId() ) {
        this.getGroups(getEventId());
      }
      this.getCountry();
      this.getState();
      this.getEvents();
    },
    computed: {
      ...mapState([
        'global',
      ]),
    },
  };
</script>
