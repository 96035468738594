import Error from "@/core/services/Error";
import {request} from "@/core/services/Request";
import {getEventId} from "@/util/globalFunction";

const DEFAULT_FORM_STATE = {
    name: null,
    email: null,
    phone: null,
    event_id: null,
    group_id: undefined,
    guest_room_id: undefined,
    pincode: null,
    state_id: null,
    country_id: 101,
    city: null,
    postal_address: null,
    _method: 'post',
};

const COLUMN_DEFINATION = (self) => [
    {
        text: "#",
        sortable: false,
        value: 'id',
    },
    {
        text: "Name",
        sortable: false,
        value: 'name',
    },
    {
        text: "Email",
        sortable: false,
        value: 'email',
    },
    {
        text: "Phone",
        sortable: false,
        value: 'phone',
    },
    {
        text: "Event Name",
        sortable: false,
        value: 'event',
    },
    {
        text: "Event Start Date",
        sortable: false,
        value: 'event_from_date',
    },
    {
        text: "Event End Date",
        sortable: false,
        value: 'event_to_date',
    },
    {
        text: "Postal Address",
        sortable: false,
        value: 'postal_address',
    },
    {
        text: "City",
        sortable: false,
        value: 'city',
    },
    (self.$global.hasPermission('guestlistsupdate') && self.$global.hasPermission('guestlistsdestroy')
        ? {
            text: "Status",
            sortable: false,
            value: 'status',
        } : {}),
    (self.$global.hasPermission('guestlistsupdate') && self.$global.hasPermission('guestlistsdestroy')
        ? {
            text: "Action",
            value: "action",
            width: 280,
        } : {}),

];

export default {
    data() {
        return {
            operationTitle: 'Guests List',
            formFields: {...DEFAULT_FORM_STATE},
            formErrors: new Error({}),
            listUrl: '/guests',
            columns: COLUMN_DEFINATION(this),
            selectEvent: undefined,
            dropdowns: {
                guestRooms: [],
                groups: [],
                countries: [],
                states: [],
                events: [],
            },
            deleteId: null,
        }
    },
    methods: {
        async sendInvitationRequest(id) {
            try {
                const response = await request({
                    url: '/guests/send/invitation/request',
                    method: 'post',
                    data: {
                        id: id
                    }
                })
                this.$global.invitationSend()
                this.loadList(this.listQueryParams)
            } catch (errors) {
                this.$global.handleServerError(errors)
            }
        },
        async sendInvitation(id) {
            try {
                const response = await request({
                    url: '/guests/send/invitation',
                    method: 'post',
                    data: {
                        id: id,
                    }
                })
                this.$global.invitationSend()
                this.loadList(this.listQueryParams)
            } catch (errors) {
                this.$global.handleServerError(errors)
            }
        },
        async getGroups(id) {
            try {
                const response = await request({
                    url: `/dropdowns/groups/${id}`,
                    method: 'post',
                })

                const {data} = response.data
                this.dropdowns.groups = data.map((item) => {
                    return {
                        id: item.id,
                        label: item.title,
                    }
                })
            } catch (e) {

            }
        },
        async getGuestRooms() {
            try {
                const response = await request({
                    url: '/dropdowns/guest/rooms',
                    method: 'post',
                })

                const {data} = response.data
                this.dropdowns.guestRooms = data.map((item) => {
                    return {
                        id: item.id,
                        label: item.room_no,
                    }
                })
            } catch (e) {

            }
        },
        async getCountry() {
            try {
                const response = await request({
                    url: '/dropdowns/countries',
                    method: 'post',
                })

                const {data} = response.data
                this.dropdowns.countries = data.map((item) => {
                    return {
                        value: item.id,
                        text: item.name,
                    }
                })
            } catch (e) {

            }
        },
        async getState() {
            try {
                const response = await request({
                    url: '/dropdowns/states',
                    method: 'post',
                })

                const {data} = response.data
                this.dropdowns.states = data.map((item) => {
                    return {
                        value: item.id,
                        text: item.name,
                        country_id: item.country_id,
                    }
                })
            } catch (e) {

            }
        },
        async getEvents() {
            try {
                const response = await request({
                    url: '/dropdowns/events',
                    method: 'post',
                })

                const {data} = response.data
                this.dropdowns.events = data.map((item) => {
                    return {
                        value: item.id,
                        text: item.title
                    }
                })

            } catch (e) {

            }
        },
        async handleSubmitOperation() {
            this.formErrors = new Error({})
            if (!this.$global.hasPermission('owncompanyprofilesview')) {
                this.formFields.event_id = getEventId()
            }
            try {
                const response = await request({
                    url: this.formFields.id ? 'guests/update' : 'guests/create',
                    method: 'post',
                    data: this.formFields,
                })
                if (this.formFields.id) {
                    this.$global.itemUpdated()
                } else {
                    this.$global.itemAdded()
                }
                this.handleOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }
                this.$global.handleServerError(error)
            }

        },
        async handleDeleteOperation(id) {
            let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
                primaryBtnText: 'Yes',
                secondaryBtnText: 'No'
            });
            if (deleteResponse) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/guests/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.$global.itemDeleted()
                } catch (errors) {
                    this.$global.handleServerError(errors)
                }
            }
        },
        async handleEditOperation(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/guests/detail/${id}`,
                })
                this.operationTitle = 'Edit Guest'
                const {data} = response.data
                this.formFields = {
                    ...data,
                    event_id: data.event ? data.event.id : undefined,
                    guest_room_id: data.guestRoom ? data.guestRoom.id : undefined,
                    group_id: data.group ? data.group.id : undefined
                }
            } catch (e) {
                this.$global.itemEditFails()
                this.formFields = {...DEFAULT_FORM_STATE}
            }
        },
        createLead(data) {
            let array = {name: data.name, email: data.email, phone: data.phone}
            if (localStorage.getItem("lead-create")) {
                localStorage.removeItem("lead-create")
            }
            localStorage.setItem("lead-create", JSON.stringify(array))

            this.$router.push({path: `leads?operation=add`})

        },
        handleOperationClose() {
            this.formFields = {...DEFAULT_FORM_STATE}
            this.$router.push({name: "guestList"})
        },
    },
}
